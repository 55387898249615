import React, { createContext, useContext, useState } from 'react';

const FeedbackContext = createContext();

export const useFeedbackContext = () => useContext(FeedbackContext);

export const FeedbackProvider = ({ children }) => {

  const [feedbackData, setFeedbackData] = useState({
    Row_Id: null,
    Property_Type: null,
  });

  const updateFeedbackData = (newData) => {
    setFeedbackData({ ...feedbackData, ...newData });
  };

  const [apiData_API, setApiData] = useState(null);

  // Function to update API data
  const updateApiData = (newData) => {
    setApiData(newData);
  };


  return (
    <FeedbackContext.Provider value={{ feedbackData, updateFeedbackData , apiData_API, updateApiData}}>
      {children}
    </FeedbackContext.Provider>
  );
};